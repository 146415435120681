<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-23 16:00:38
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-26 13:33:47
-->
<template>
	<div id="main-page-div">
        <el-row>
            <el-col :span="12">
                <el-card class="box-card myinfo">
                    <div slot="header">
                        <span style="font-size: 20px;">水电站生态流量实时概况</span>
                    </div>
                    <el-col :span="10">
                        <div style="width: auto; height: 300px;">
                            <my-echarts :option="traffic.option" :is-loading="traffic.isLoading"></my-echarts> 
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div style="margin-top: 20px;">
                            <div style="float: left"><div class="geodiv"></div>考核水电站：22个</div>
                            <div style="float: right"><div class="geodiv"></div>免考核水电站：0个</div>
                        </div>
                        <div style="height: 10px;"></div>
                        <br><br><hr>
                        <div style="height: 40px;"></div>
                        <div style="width: 100%;">
                            <div style="width: 49%;float: left;">
                                达标：{{total}} 个
                                <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress>
                                <div style="height: 30px;"></div>
                                未达标：{{total}} 个
                                <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress>
                            </div>
                            <div style="width: 49%;float: right;">
                                正常：{{total}} 个
                                <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress>
                                <div style="height: 30px;"></div>
                                中断：{{total}} 个
                                <el-progress :text-inside="true" :stroke-width="26" :percentage="70"></el-progress>
                            </div>
                        </div>
                    </el-col>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card myinfo myinfo-right">
                    <div slot="header">
                        <span style="font-size: 20px;">水电站年生态电价补贴标准统计</span>
                    </div>
                    <el-col :span="24">
                        <div style="width: 100%; height: 300px;">
                            <my-echarts :option="subsidy.option" :is-loading="subsidy.isLoading"></my-echarts> 
                        </div>
                    </el-col>
                </el-card>
            </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
            <el-col :span="24">
                <el-card class="box-card year-report">
                    <sys-report></sys-report>
                </el-card>
            </el-col>
        </el-row>
	</div>
</template>
<script>
    const MyEcharts= ()=> import ("@/views/second/ECharts")
    const sysReport= ()=> import ("@/views/second/components/sysReport")
	export default {
		data() {
			return {
				loading: false,
				// page
				page: {
					size: 10,
					curPage: 1
				},
                total: 30,
                traffic:{
                    isLoading: false,
                    option:{
                        tooltip: {
                            trigger: 'item'
                        },
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['40%', '70%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        // fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    { 
                                        value: 100, 
                                        name: '水电站总数', 
                                        label: { 
                                            normal: { 
                                                show: true, 
                                                fontSize: '20',
                                                formatter: function(params) {
                                            　　　　　　return params.name + '\n' + params.value;
                                    　　　　        }
                                            },
                                        } 
                                    },
                                ]
                            }
                        ]
                    }
                },
                subsidy:{
                    isLoading: false,
                    option:{
                        legend: {
                            orient: 'vertical',
                            right: '10px',
                            top: '60px',
                            data: ['补贴1分水电站', '补贴2分水电站', '补贴3分水电站', '补贴4分水电站', '补贴5分水电站'],
                            textStyle: {
                                fontSize: 16
                            }
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: ['40%', '70%'],
                                center: ['35%', '45%'],
                                data: [
                                    {value: 500, name: '补贴1分水电站'},
                                    {value: 500, name: '补贴2分水电站'},
                                    {value: 500, name: '补贴3分水电站'},
                                    {value: 500, name: '补贴4分水电站'},
                                    {value: 500, name: '补贴5分水电站'}
                                ],
                                label: {
                                    normal: {
                                        textStyle:{
                                            fontSize: 16
                                        }
                                    }
                                },
                            }
                        ]
                    }
                }
			};
		},
        components: {
            MyEcharts,
            sysReport,
        },
		created() {
			// this.dataRequest();
		},
		computed: {
			dataArr() {
				return this.$store.getters.getCurDevices;
			},
			dataArrFilter() {
				let page = this.page;
				let arr = this.dataArr.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
					page.curPage * page.size) - 1));
				return arr;
			}
		},
		watch: { },
		methods: {
			dataRequest() {
				let json = {
					"cmd": "devices"
				}
				this.ws.send(json);
			},
		},
		mounted() {
			this.dataRequest();
			
			this.ws.addCallback('devicesAck', (res) => {
			})
		
			this.ws.addCallback('dataStoreAck', (res) => {
			})
		
			this.ws.addCallback('setDataStoreAck', (res) => {
			})
		}
	};
</script>

<style lang="scss">
    .myinfo{
        width: 96%;
    }
    .year-report{
        width: 98%;
    }
    .geodiv{
        background-color: rgb(75, 68, 68);
        width: 20px;
        height: 20px;
        float: left;
    }  
    
    .el-dialog__header {
        text-align: center;
        background-color: rgb(59, 133, 231);
    }
    
    // .el-icon-close{
    //     color: white !important;
    //     font-size: 20px !important;
    // }
    .el-select .el-input {
        width: 130px;
    }
    // .input-with-select .el-input-group__prepend {
    //     background-color: #fff;
    // }
</style>
